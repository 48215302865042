(function ($) {
    if (!$("html.ie").length && !$("html.no-sr").length && $("html.sr").length) {

        let reveal_group = [];
        let reveal_element = [];

        let options = {
            delay: 0,
            distance: 0,
            duration: 0,
            scale: 1,
            opacity: null,
            viewFactor: 0.2,
            mobile: true,
            rotate: {
                x: 0,
                y: 0,
                z: 0
            }
        };

        let callbacks = {
            beforeReveal: function (el) {
                $(el).attr("data-reveal", "revealed");
                if($(el).is("[data-google-map]")) {
                    let map = $(el);
                    $.importScript(cdnjs.googlemap.replace("APIKEY", map.data("google-map")), function(){
                        $.importScript(cdnjs.googlemaplabel, function () {
                            map.nl_google_map("init");
                        });
                    });
                }
            },
            afterReveal: function(el) {
                $(el).removeAttr("style");
            }
        };

        $("[data-reveal-group]").each(function () {
            let d = $(this).data("reveal-group");
            reveal_group.indexOf(d) === -1 && reveal_group.push(d);
        });

        $("[data-reveal]").each(function () {
            let name = $(this).data("reveal");
            if (name !== "single") {
                reveal_element.indexOf(name) === -1 && reveal_element.push(name);
            }
        });

        bodyLoaded(function () {
            ScrollReveal().reveal('[data-reveal="single"]', $.extend(options, callbacks, {interval: null}));

            reveal_group.forEach(function (d) {
                reveal_element.forEach(function (e) {
                    ScrollReveal().reveal(`[data-reveal-group="${d}"] [data-reveal="${e}"]`, $.extend(options, callbacks, {interval: d}));
                });
            });
        });
    } else {
        html.addClass("no-sr")
    }
})(jQuery);