(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let carousels = selector.find(".part_item_carousel_work");

        win.on("scroll", function () {
            carousels.each(function () {
                let elm = $(this);
                if (win.scrollTop() - (elm.height() / 2.5) > elm.offset().top - (win.height() / 2) && win.scrollTop() - (elm.height() / 2) < elm.offset().top - (win.height() / 2) + elm.height()) {
                    elm.addClass("is--hover");
                } else {
                    elm.removeClass("is--hover");
                }
            });
        });

        $.lib_flickity(function(){
            carousels.each(function () {
                let el = $(this),
                    carousel = el.find("[data-carousel]");

                carousel.flickity({
                    imagesLoaded: false,
                    percentPosition: false,
                    setGallerySize: true,
                    pageDots: false,
                    wrapAround: true,
                    contain: true,
                    autoPlay: false,
                    prevNextButtons: true,
                    draggable: true,
                    cellAlign: "left"
                });
            })
        });

        window.lui[name] = comp;
    }
})("comp_list_work");