$.fn.nl_google_map = function(event,data) {
    return this.each(function() {
        const el = $(this);
        if (!el.length) {
            return false;
        }
        if (event === 'init') {
            nl_google_map_init();
        }

        function nl_google_map_init() {
                let zoom;
                if (win.width() > 768) {
                    zoom = el.data("zoom");
                } else {
                    zoom = 14;
                }
                let coords = el.data('coords').split(/[\s,]+/),
                markers_temp = [],
                map_options = {
                    zoom: zoom,
                    center: {
                        lat: parseFloat(coords[0]), lng: parseFloat(coords[1])
                    },
                    disableDefaultUI: false,
                    styles: [
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#e9e9e9"
                                },
                                {
                                    "lightness": 17
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                },
                                {
                                    "lightness": 20
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 17
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 29
                                },
                                {
                                    "weight": 0.2
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 18
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 16
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                },
                                {
                                    "lightness": 21
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#dedede"
                                },
                                {
                                    "lightness": 21
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "visibility": "on"
                                },
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 16
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "saturation": 36
                                },
                                {
                                    "color": "#333333"
                                },
                                {
                                    "lightness": 40
                                }
                            ]
                        },
                        {
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f2f2f2"
                                },
                                {
                                    "lightness": 19
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#fefefe"
                                },
                                {
                                    "lightness": 20
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#fefefe"
                                },
                                {
                                    "lightness": 17
                                },
                                {
                                    "weight": 1.2
                                }
                            ]
                        }
                    ]
                };

            const map = new google.maps.Map(el[0], map_options);

            let bounds = new google.maps.LatLngBounds();

            if (el.filter("[data-marker]").length) {
                let main_marker_position = new google.maps.LatLng(parseFloat(coords[0]), parseFloat(coords[1]));
                let main_marker_image = {
                    url: el.data("marker"),
                    anchor: new google.maps.Point(40, 80)
                };

                let main_marker = new MarkerWithLabel({
                    position: main_marker_position,
                    map: map,
                    zIndex: 9999,
                    icon: main_marker_image,
                    labelContent: el.data("label"),
                    labelAnchor: new google.maps.Point(-45, 45),
                    labelClass: "elm_map_label",
                    labelInBackground: true
                });

                markers_temp.push(main_marker);
                bounds.extend(main_marker_position);
            }
        }
    });
};