jQuery.libInit = function(s,n,callback) {
    s = $lui(s);
    (function fn(selector,name) {
        if (selector.length) {
            callback(s.selector)
        }

        if (!window.lui.lib[name]) {
            window.lui.lib[name] = fn;
            window.lui.lib[name].selector = [selector.selector,name];
        }
    })(s,n);
};