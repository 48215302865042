(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let slider = selector.find("[data-carousel-image]"),
            autoplay = slider.data("carousel-image"),
            slider_sync = doc.find(".comp_home").find("[data-carousel-content]").get(0);

        win.on("scroll", function(){
            if (win.scrollTop() > win.height()) {
                selector.addClass("is--full");
            } else {
                selector.removeClass("is--full");
            }
        });

        $.lib_flickity(function(){
            $.importScript(cdnjs.flickity_sync,()=>{
                if (slider.children().length > 1) {
                    slider.flickity({
                        imagesLoaded: false,
                        percentPosition: false,
                        setGallerySize: false,
                        pageDots: false,
                        wrapAround: true,
                        contain: true,
                        autoPlay: autoplay,
                        prevNextButtons: false,
                        pauseAutoPlayOnHover: false,
                        draggable: false,
                        sync: slider_sync
                    });
                }
            });
        });

        window.lui[name] = comp;
    }
})("comp_visual_main");