(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let slider = selector.find("[data-carousel-content]");

        $.lib_flickity(function(){
            $.importScript(cdnjs.flickity_sync,()=>{
                if (slider.children().length > 1) {
                    slider.flickity({
                        imagesLoaded: false,
                        percentPosition: false,
                        setGallerySize: false,
                        pageDots: false,
                        wrapAround: true,
                        contain: true,
                        prevNextButtons: false,
                        pauseAutoPlayOnHover: false,
                        draggable: false,
                    });
                }
            });
        });

        window.lui[name] = comp;
    }
})("comp_home");